/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import PropTypes from 'prop-types';
import * as React from 'react';
// import { useStaticQuery, graphql } from "gatsby"
// import Header from "./header"
import './layout.css';
import { Main, MainWrapper } from './layout.style';

const Layout = ({ children }) => {
  const isBrowser = typeof window !== 'undefined';

  return (
    <>
      {isBrowser && (
        <MainWrapper>
          <Main>{children}</Main>
          {/* <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          © {new Date().getFullYear()}, Design by
          {` `}
          Digital Garden Global Network
        </footer> */}
        </MainWrapper>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
