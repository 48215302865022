export const colors = {
  white: "#ffffff",
  jetblack: "#292929",
  silver: "#757575",
  mikadoyellow: "#ffc806",
  brownishgrey: "#757575",
  lightgrey: "#efefef",
  grey: "#808080",
  powderwhite: "#fafafa",
  lightgreen: "#f2f7f5",
  niceblue: "#1454b9",
  lesemester: "#fcfcff",
  sensix: "#eff3f3",
  ledidi: "#eaf0f5",

  russianblack: "#171D2B",
  freespeechred: "#D50000"
};
