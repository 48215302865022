import styled from 'styled-components';
import { colors } from '../consts/colors';
import {
  Row as BSRow,
  Container as BSContainer,
  Col as BSCol,
} from 'reactstrap';

export const MenuTitle = styled.p`
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 8rem;
  color: ${colors.jetblack};
`;

export const BigLink = styled.a`
  font-family: 'Poppins', sans-serif;
  font-size: 4.8rem;
  line-height: 5.7rem;
  font-weight: 600;
  display: block;
  color: ${colors.jetblack}!important;
  margin-bottom: 2rem;
  cursor: pointer;
  :hover {
    color: ${colors.jetblack}!important;
  }
  @media (max-width: 1024px) {
    font-size: 4.8rem;
    line-height: 5.7rem;
  }
  @media (max-width: 767px) {
    font-size: 3.6rem;
    line-height: 4.3rem;
  }
`;

export const SmallLink = styled.a`
  font-size: 2.4rem;
  line-height: 3.3rem;
  display: block;
  font-family: 'Roboto', sans-serif;
  color: ${colors.jetblack};
  margin-bottom: 1rem;
  cursor: pointer;
  :hover {
    color: ${colors.jetblack};
  }
  &:not([href]) {
    color: ${colors.jetblack};
    &:hover {
      color: ${colors.jetblack};
    }
  }
`;

export const MainWrapper = styled.div`
  width: 100vw;
  overflow-x: hidden;
  position: relative;
`;

export const Menu = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: fixed;
  top: 0;
  display: flex;
  background-color: ${colors.mikadoyellow};
  color: ${colors.white};
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  ${({ visible }) =>
    visible
      ? `
    right: -50vw;
    opacity: 0;
    transition: right 700ms cubic-bezier(0.86, 0.01, 0.77, 0.78),
      opacity 700ms linear 700ms;
  `
      : `
    right: 0;
    opacity: 1;
    transition: right 700ms cubic-bezier(0.86, 0.01, 0.77, 0.78),
      opacity 700ms;
  `};
`;

export const Container = styled(BSContainer)`
  min-height: 100vh;
`;

export const Row = styled(BSRow)`
  min-height: 100vh;
`;

export const Col = styled(BSCol)``;

export const Main = styled.main`
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  z-index: 2;
  background-color: ${colors.white};
  position: relative;
  ${({ visible }) =>
    visible
      ? `
    right: 100vw;
  `
      : `
    right: 0;
  `};
  transition: right 700ms cubic-bezier(0.86, 0.01, 0.77, 0.78);
`;
